import { Injectable } from "@angular/core";

import
{
  Observable, catchError
} from "rxjs";

import
{
  APIService, unwrapResponse
} from "./api.service";

import
{
  ErrorHandlerCreator, ResponseErrorHandler
} from "./response-error-handler.service";

import { Address } from "../models/address";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class AddressService
{
  static readonly url = "Address";

  private handleError: ErrorHandlerCreator;

  constructor(private readonly api: APIService,
    private readonly responseErrorHandler: ResponseErrorHandler
  )
  {
    this.handleError = this.responseErrorHandler.createErrorHandler("AddressService");
  }

  getAddressesByPersonId(personId: string): Observable<Address[]>
  {
    return this.api.get<Address[]>(`${AddressService.url}/${personId}`).pipe(
      unwrapResponse(),
      catchError(this.handleError("getAddressesByPersonId", new Array<Address>()))
    );
  }


  checkIfBuildingOrEntranceExists(fieldName: string, entranceNumber?: number | string, cityCode?: number | string, streetCode?: number | string, buildingNumber?: number | string)
  {
    let params = new HttpParams();

    params = params
      .set("cityCode", cityCode ?? "0")
      .set("streetCode", streetCode ?? "0")
      .set("buildingNumber", buildingNumber ?? "0")
      .set("fieldName", fieldName)
      .set("entranceNumber", entranceNumber ?? "");
    const options = { params: params };

    return this.api.get<boolean>(
      AddressService.url + "/CheckIfBuildingOrEntranceExists", options
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("CheckIfBuildingOrEntranceExists"))
    );
  }

  getNeighborhoodAndEntrance(cityCode?: number | string, streetCode?: number | string, buildingNumber?: number | string)
  {
    let params = new HttpParams();

    params = params
      .set("cityCode", cityCode ?? "0")
      .set("streetCode", streetCode ?? "0")
      .set("buildingNumber", buildingNumber ?? "0");
    const options = { params: params };

    return this.api.get<Record<string, string>>(
      AddressService.url + "/getNeighborhoodAndEntrance", options
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("getNeighborhoodAndEntrance"))
    );
  }
}